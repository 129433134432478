.primaryBanner {
  display: flex;
  padding: 0 clamp(0rem, 16vw, 20rem);
  justify-content: space-between;
  margin-top: clamp(5rem, 6vh, 8rem);
}

.imgContainer {
  display: flex;
  position: relative;
  margin: 0 0 0 2rem;
}

.primaryBannerImage {
  width: 22vw;
  height: 22vw;
  min-height: 20rem;
  min-width: 20rem;
  object-fit: cover;
  border-radius: 10px;
}

.iconLogoRound {
  position: absolute;
  top: -4rem;
  left: -4rem;
  width: 8rem;
  height: 8rem;
}

.primaryBanner h2 {
  background-color: transparent;
  font-weight: 600;
}

.textContainer {
  /* padding: 0; */
  margin: 0 2rem 0 0;
}

.textContainer h2 {
  color: #000;
  margin-bottom: 1vh;
}

.textDescription {
  color: #000;
  font-size: 14px;
  font-weight: lighter;
  white-space: pre-wrap;
  margin: 0 0 2rem 0;
}

@media (max-width: 768px) {
  .primaryBanner {
    display: flex;
    flex-direction: column;
    padding: 0 clamp(0rem, 10vw, 3rem);
  }

  .primaryBanner h2 {
    font-size: 1.6rem;
  }

  .textContainer {
    top: clamp(14rem, 65%, 90%);
  }

  .callNowBtn {
    padding: 0 0;
  }

  .imgContainer {
    margin: 5vh 0 4vh 0;
    max-height: 22rem;
    max-width: 22rem;
    align-self: center;
  }

  .primaryBannerImage {
    margin: 0 0 0;
    object-fit: contain;
    border-radius: 12px;
    min-height: 22rem;
    min-width: 22rem;
    align-self: center;
  }

  .iconLogoRound {
    top: -3rem;
    left: -2rem;
    width: 6rem;
    height: 6rem;
  }
}
