.introduction {
  padding: 2vh clamp(0rem, 16vw, 20rem) 3vh clamp(0rem, 16vw, 20rem);
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--background-color);
}

.introTextContainer {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 5vw;
}

.introTextContainer h1 {
  font-weight: bold;
  color: var(--primary);
  text-align: center;
  margin: 0 0 2vh 0;
}

.introTextContainer h3,
p {
  margin: 8px 0;
}

.greyIntroTextContainer {
  color: #000;
  text-align: center;
  line-height: 27px;
}

.imgContainer {
  padding: 0 0 0 0;
  height: 65vh;
  user-select: none;
  align-items: flex-start;
}

.introImg  {
  height: 100%;
  max-width: 30vw;
  object-fit: cover;
  user-select: none;
}

.introHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  padding: 2vh 0;
}

.cursiveText {
  font-family: Rumble_Brave, sans-serif;
  font-size: 3rem;
  margin: 0 0 1vh 0;
  color: var(--primary);
}

@media (max-width: 768px) {
  .introduction {
    padding: 1rem 0 0 0;
  }

  .rowWrapper {
    flex-direction: column-reverse;
    padding: 0 0 3vh 0;
  }

  .introHeader {
    padding: 1vh 0;
  }

  .introTextContainer {
    width: 85vw;
    align-items: center;
    align-self: center;
  }

  .introTextContainer p {
    margin: 0 0 2rem;
  }

  .introTextContainer h1 {
    text-align: center;
    font-size: 1.2rem;
  }

  .introTextContainer h3 {
    text-align: center;
  }

  .cursiveText {
    font-size: 2.3rem;
    margin: 0;
    text-align: center;
  }

  .greyIntroTextContainer {
    color: var(--grey-color);
    text-align: left;
    line-height: 20px;
    font-size: 1rem;
  }

  .introImg {
      max-width: 90vw;
      margin-top: 4vh;
  }
  
}
